import api from "./index";

const postLogin = (data) =>
  api.post("oauth/token", {
    ...data,
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    grant_type: "password",
  });

const postForgot = (data) => api.post("auth/forgot", data);
const putAuthOTPValidate = (data) => api.put("auth/otp-validate", data);
const putAuthResetPassword = (data) => api.put("auth/reset-password", data);

export { postLogin, postForgot, putAuthOTPValidate, putAuthResetPassword };
