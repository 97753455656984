import api from "./index";

const prefix = "profile";
const getProfile = () => api.get(prefix);
const getProfileAddress = () => api.get(`${prefix}/address`);
const postPassword = (data) => api.post(`${prefix}/password`, data);
const postProfilePhoto = (data) =>
  api.post(`${prefix}/photo`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export { getProfile, getProfileAddress, postPassword, postProfilePhoto };
